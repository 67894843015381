import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';
import Table from 'react-bootstrap/Table';
import './GoldPrices.css';

const ENDPOINT = "https://socket.haremaltin.com";

const variablesToShow = {
  USDTRY: "Dolar TL",
  ALTIN: "Has Altın",
  ONS: "Ons fiyatı",
  AYAR22: "22 Ayar",
  AYAR14: "14 Ayar",
  CEYREK_YENI: "Çeyrek Altın - Yeni",
  CEYREK_ESKI: "Çeyrek Altın - Eski",
  YARIM_YENI: "Yarım Altın - Yeni",
  YARIM_ESKI: "Yarım Altın - Eski",
  ATA_YENI: "Ata/Tam Altın - Yeni",
  ATA_ESKI: "Ata/Tam Altın - Eski",
  GUMUSTRY: "Gümüş Gram"
};

const additionalVariables = {
  GRAM_ALTIN_995: "Gram Altın (995)",
  GRAM_ALTIN_925: "Gram Altın (925)"
};

const GoldPrices = () => {
  const [prices, setPrices] = useState({});
  const [updatedKeys, setUpdatedKeys] = useState([]);

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT, {
      transports: ['websocket'],
      extraHeaders: {
      }
    });

    socket.on('price_changed', (data) => {
      if (data.data) {
        const filteredData = Object.keys(data.data)
          .filter(key => key in variablesToShow)
          .reduce((obj, key) => {
            obj[key] = data.data[key];
            return obj;
          }, {});

        // Gram Altın (995) ve Gram Altın (925) hesaplamaları
        if (filteredData.ALTIN) {
          filteredData.GRAM_ALTIN_995 = {
            alis: (parseFloat(filteredData.ALTIN.alis) * 0.995).toFixed(0),
            satis: (parseFloat(filteredData.ALTIN.satis) * 0.995).toFixed(0),
            tarih: filteredData.ALTIN.tarih
          };

          filteredData.GRAM_ALTIN_925 = {
            alis: (parseFloat(filteredData.ALTIN.alis) * 0.925).toFixed(0),
            satis: (parseFloat(filteredData.ALTIN.satis) * 0.925).toFixed(0),
            tarih: filteredData.ALTIN.tarih
          };
        }

        setPrices(prevPrices => {
          const newPrices = { ...prevPrices, ...filteredData };
          setUpdatedKeys(Object.keys(filteredData));
          return newPrices;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (updatedKeys.length > 0) {
      const timer = setTimeout(() => {
        setUpdatedKeys([]);
      }, 500); // Yanıp sönme süresi (ms)
      return () => clearTimeout(timer);
    }
  }, [updatedKeys]);

  const formatPrice = (key, price) => {
    return key === 'USDTRY' ? price : parseInt(price, 10);
  };

  const sortedVariablesToShow = [
    'USDTRY',
    'ALTIN',
    'GRAM_ALTIN_995',
    'GRAM_ALTIN_925',
    ...Object.keys(variablesToShow).filter(key => key !== 'ALTIN' && key !== 'USDTRY')
  ];

  return (
    <div className="gold-prices">
      <Table striped bordered hover className="table">
        <thead className="thead-dark">
          <tr>
            <th>Değişken</th>
            <th>Alış</th>
            <th>Satış</th>
            {/* <th>Güncelleme Zamanı</th> */}
          </tr>
        </thead>
        <tbody>
          {sortedVariablesToShow.map(key => (
            prices[key] && (
              <tr key={key} className={updatedKeys.includes(key) ? 'highlight' : ''}>
                <td>{variablesToShow[key] || additionalVariables[key]}</td>
                <td>{formatPrice(key, prices[key].alis)}</td>
                <td>{formatPrice(key, prices[key].satis)}</td>
                {/* <td>{prices[key].tarih}</td> */}
              </tr>
            )
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default GoldPrices;